import request from '@/utils/request'



// 查询订单发货记录列表
export function listDelivery(query) {
  return request({
    url: '/order/delivery/list',
    method: 'get',
    params: query
  })
}

// 查询订单发货记录分页
export function pageDelivery(query) {
  return request({
    url: '/order/delivery/page',
    method: 'get',
    params: query
  })
}

// 查询订单发货记录详细
export function getDelivery(data) {
  return request({
    url: '/order/delivery/detail',
    method: 'get',
    params: data
  })
}


